package org.hdg.sanaseppa.api

import kotlinx.serialization.Serializable

@Serializable
data class Response(
    val statusCode: Int,
    val body: String,
)

@Serializable
data class Game(
    val id: String,
    val word: String,
    val chosenLetter: String,
    val letters: List<String>,
    val allWords: List<String>
)

@Serializable
data class GameResponse(
    val game: Game
)
