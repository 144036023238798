package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.material3.FilledTonalButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
@Preview
fun RoundButton(onClick: () -> Unit, text: String = "A", isMiddle: Boolean = false, size: Dp = 64.dp) {
    val buttonModifier = Modifier.size(size)
    val buttonContentPadding = PaddingValues(0.dp)
    val buttonShape = CircleShape
    val fontSize = 32.sp

    if (isMiddle) {
        Button(modifier = buttonModifier,
            contentPadding = buttonContentPadding,
            shape = buttonShape,
            onClick = onClick)
        {
            Text(fontSize = fontSize, text = text.uppercase())
        }
    } else {
        FilledTonalButton(modifier = buttonModifier,
            contentPadding = buttonContentPadding,
            shape = buttonShape,
            onClick = onClick) {
            Text(fontSize = fontSize, text = text.uppercase())
        }
    }
}