package org.hdg.sanaseppa.components

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.ui.tooling.preview.Preview
import sanaseppa.composeapp.generated.resources.Res
import ui.theme.primaryDark
import ui.theme.primaryLight
import ui.theme.secondaryContainerLight
import ui.theme.secondaryContainerDark
import ui.theme.onPrimaryDark
import ui.theme.onPrimaryLight
import kotlin.math.ceil
import sanaseppa.composeapp.generated.resources.lucide_up
import kotlin.math.floor

val RANK_NAMES =
    arrayOf(
        "Noviisi",
        "Kisälli",
        "Ässä",
        "Ekspertti",
        "Velho",
        "Mestari",
        "Virtuoosi",
        "Nero",
        "Sanaseppä"
    )

@Composable
@Preview
fun GameProgress(
    stateProgress: Double,
    hideProgress: Boolean,
    isDarkTheme: Boolean,
    guessedWordsCount: Number,
    allWordsCount: Number,
    stateShowRanksDialog: MutableState<Boolean>
) {
    val num = allWordsCount.toDouble().div(RANK_NAMES.size - 1)
    val selectedIndex = minOf(floor(guessedWordsCount.toDouble() / num).toInt(), RANK_NAMES.size - 1)
    val nextRankPointsNeeded = ceil((selectedIndex + 1) * num)
    val pointsNeededForNextRank = floor(nextRankPointsNeeded - guessedWordsCount.toDouble()).toInt()
    val rank = RANK_NAMES[selectedIndex]

    AnimatedVisibility(stateShowRanksDialog.value) {
        RanksDialog(
            stateShowRanksDialog,
            guessedWordsCount,
            RANK_NAMES,
            pointsNeededForNextRank,
            num,
            selectedIndex,
            isDarkTheme
        )
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.clickable { stateShowRanksDialog.value = true }
    ) {
        if (stateProgress < 1) {
            Text(
                color = if (isDarkTheme) primaryDark else primaryLight,
                text = "${((stateProgress * 100).toInt().toString())}%  ",
                fontSize = 16.sp,
                fontWeight = FontWeight.Medium,
                letterSpacing = .0.sp,
            )
        }

        Text(
            color = if (isDarkTheme) primaryDark else primaryLight,
            text = rank,
            fontSize = 16.sp,
            fontWeight = FontWeight.Bold,
            letterSpacing = .0.sp,
        )
    }


    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.widthIn(0.dp, 800.dp).padding(top = 6.dp, bottom = 6.dp)
            .clickable { stateShowRanksDialog.value = true }
    )
    {
        Column(
            modifier = Modifier
                .weight(1f)
                .fillMaxWidth()
        ) {
            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                if (!hideProgress) {
                    Canvas(
                        modifier = Modifier.fillMaxWidth().height(4.dp) // Adjust the height as needed
                    ) {
                        drawLine(
                            color = if (isDarkTheme) secondaryContainerDark else secondaryContainerLight,
                            start = Offset(0f, size.height / 2),
                            end = Offset(size.width, size.height / 2),
                            strokeWidth = 2.dp.toPx() // Adjust the stroke width as needed
                        )
                    }
                }

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    for (i in RANK_NAMES.indices) {
                        if (selectedIndex == i) {
                            Box(
                                contentAlignment = Alignment.Center,
                                modifier = Modifier.size(32.dp).clip(CircleShape)
                                    .background(if (isDarkTheme) primaryDark else primaryLight)
                            ) {
                                Text(
                                    modifier = Modifier.padding(bottom = 5.dp),
                                    fontSize = 14.sp,
                                    text = if (allWordsCount != 0) guessedWordsCount.toString() else "0",
                                    color = if (isDarkTheme) onPrimaryDark else onPrimaryLight
                                )
                            }
                        } else {
                            val color =
                                if (selectedIndex > i) if (isDarkTheme) primaryDark else primaryLight else if (isDarkTheme) secondaryContainerDark else secondaryContainerLight
                            Box(
                                modifier = Modifier.size(12.dp).clip(CircleShape)
                                    .background(color)
                            )
                        }
                    }
                }
            }
        }
        if (selectedIndex != RANK_NAMES.size - 1) {
            Column(
                modifier = Modifier.widthIn(0.dp, 400.dp).padding(start = 16.dp)
            ) {
                Box(
                    contentAlignment = Alignment.Center,
                    modifier = Modifier.size(32.dp).clip(CircleShape)
                        .background(if (isDarkTheme) primaryDark else primaryLight)
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.lucide_up),
                        contentDescription = null,
                        tint = if (isDarkTheme) onPrimaryDark else onPrimaryLight,
                        modifier = Modifier.size(20.dp)
                    )
                }
            }
            Column(
                modifier = Modifier.widthIn(0.dp, 400.dp).padding(start = 8.dp)
            ) {
                Text(
                    color = if (isDarkTheme) primaryDark else primaryLight,
                    text = pointsNeededForNextRank.toString(),
                    fontSize = 18.sp,
                )
            }
        }
    }
}