package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.unit.dp

@Composable
fun InputButtons(
    stateUserInput: MutableState<String>,
    stateGameLetters: MutableState<Array<String>>,
    stateChosenGameLetter: MutableState<String>
) {
    Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
        RoundButton({ stateUserInput.value += stateGameLetters.value[0] }, stateGameLetters.value[0])
        RoundButton({ stateUserInput.value += stateGameLetters.value[1] }, stateGameLetters.value[1])
    }
    Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
        RoundButton({ stateUserInput.value += stateGameLetters.value[2] }, stateGameLetters.value[2])
        RoundButton(
            { stateUserInput.value += stateChosenGameLetter.value },
            stateChosenGameLetter.value,
            isMiddle = true
        )
        RoundButton({ stateUserInput.value += stateGameLetters.value[3] }, stateGameLetters.value[3])
    }
    Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
        RoundButton({ stateUserInput.value += stateGameLetters.value[4] }, stateGameLetters.value[4])
        RoundButton({ stateUserInput.value += stateGameLetters.value[5] }, stateGameLetters.value[5])
    }
}