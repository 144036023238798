package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.*
import androidx.compose.material3.Button
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.dp
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.withStyle
import kotlin.text.Typography.bullet

@Composable
fun InfoScreen(stateShowInfo: MutableState<Boolean>) {
    val list = listOf(
        "Peli vaihtuu päivittäin",
        "Tarkoituksena on muodostaa sanoja annetuista kirjaimista",
        "Sanojen tulee sisältää keskimmäinen kirjain",
        "Sanat tulee olla perusmuodossa",
        "Voit käyttää samaa kirjainta useaan kertaan",
        "Sanoissa on oltava vähintään neljä kirjainta",
        "Erisnimet, paikannimet ja maiden nimet eivät kelpaa"
    )
    Column(
        Modifier.fillMaxHeight().widthIn(0.dp, 800.dp),
    ) {
        Text(fontSize = 24.sp, fontWeight = FontWeight.Medium, text = "Ohjeet")
        Spacer(modifier = Modifier.height(24.dp))
        val paragraphStyle = ParagraphStyle()
        Text(
            buildAnnotatedString {
                list.forEach {
                    withStyle(style = paragraphStyle) {
                        append(bullet)
                        append(" ")
                        append(it)
                    }
                }
            },
            fontSize = 16.sp
        )
        Spacer(modifier = Modifier.height(24.dp))
        Text(
            fontSize = 16.sp,
            text = "Pelissä käytetään Kotimaisten kielten keskuksen Nykysuomen sanalistaa."
        )
        Spacer(modifier = Modifier.weight(0.5f))
        Button(modifier = Modifier.fillMaxWidth(), onClick = { stateShowInfo.value = false }) {
            Text("Sulje")
        }
        Spacer(modifier = Modifier.weight(0.05f))
    }
}