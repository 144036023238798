@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package sanaseppa.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val KumbhSans_Black: FontResource by 
      lazy { init_KumbhSans_Black() }

  public val KumbhSans_Bold: FontResource by 
      lazy { init_KumbhSans_Bold() }

  public val KumbhSans_ExtraBold: FontResource by 
      lazy { init_KumbhSans_ExtraBold() }

  public val KumbhSans_ExtraLight: FontResource by 
      lazy { init_KumbhSans_ExtraLight() }

  public val KumbhSans_Light: FontResource by 
      lazy { init_KumbhSans_Light() }

  public val KumbhSans_Medium: FontResource by 
      lazy { init_KumbhSans_Medium() }

  public val KumbhSans_Regular: FontResource by 
      lazy { init_KumbhSans_Regular() }

  public val KumbhSans_SemiBold: FontResource by 
      lazy { init_KumbhSans_SemiBold() }

  public val KumbhSans_Thin: FontResource by 
      lazy { init_KumbhSans_Thin() }
}

internal val Res.font.KumbhSans_Black: FontResource
  get() = CommonMainFont0.KumbhSans_Black

private fun init_KumbhSans_Black(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_Black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-Black.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_Bold: FontResource
  get() = CommonMainFont0.KumbhSans_Bold

private fun init_KumbhSans_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-Bold.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_ExtraBold: FontResource
  get() = CommonMainFont0.KumbhSans_ExtraBold

private fun init_KumbhSans_ExtraBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-ExtraBold.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_ExtraLight: FontResource
  get() = CommonMainFont0.KumbhSans_ExtraLight

private fun init_KumbhSans_ExtraLight(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_ExtraLight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-ExtraLight.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_Light: FontResource
  get() = CommonMainFont0.KumbhSans_Light

private fun init_KumbhSans_Light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-Light.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_Medium: FontResource
  get() = CommonMainFont0.KumbhSans_Medium

private fun init_KumbhSans_Medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-Medium.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_Regular: FontResource
  get() = CommonMainFont0.KumbhSans_Regular

private fun init_KumbhSans_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-Regular.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_SemiBold: FontResource
  get() = CommonMainFont0.KumbhSans_SemiBold

private fun init_KumbhSans_SemiBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-SemiBold.ttf", -1, -1),
    )
)

internal val Res.font.KumbhSans_Thin: FontResource
  get() = CommonMainFont0.KumbhSans_Thin

private fun init_KumbhSans_Thin(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:KumbhSans_Thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/font/KumbhSans-Thin.ttf", -1, -1),
    )
)
