package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
@Preview
fun RoundIconButton(onClick: () -> Unit, size: Dp = 74.dp, imageVector: ImageVector ) {
    val buttonModifier = Modifier.size(size)
    val buttonContentPadding = PaddingValues(0.dp)
    val buttonShape = CircleShape

    FilledTonalButton(
        modifier = buttonModifier,
        contentPadding = buttonContentPadding,
        shape = buttonShape,
        onClick = onClick
    ) {
        Icon(
            imageVector = imageVector,
            contentDescription = "pyöräytä"
        )
    }

}