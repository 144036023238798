package ui.theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Typography
import androidx.compose.material3.lightColorScheme
import androidx.compose.material3.darkColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import org.jetbrains.compose.resources.Font
import sanaseppa.composeapp.generated.resources.*
import sanaseppa.composeapp.generated.resources.Res


private val LightColors = lightColorScheme(
    primary = primaryLight,
    onPrimary = onPrimaryLight,
    primaryContainer = primaryContainerLight,
    onPrimaryContainer = onPrimaryContainerLight,
    secondary = secondaryLight,
    onSecondary = onSecondaryLight,
    secondaryContainer = secondaryContainerLight,
    onSecondaryContainer = onSecondaryContainerLight,
    tertiary = tertiaryLight,
    onTertiary = onTertiaryLight,
    tertiaryContainer = tertiaryContainerLight,
    onTertiaryContainer = onTertiaryContainerLight,
    error = errorLight,
    onError = onErrorLight,
    errorContainer = errorContainerLight,
    onErrorContainer = onErrorContainerLight,
    background = backgroundLight,
    onBackground = onBackgroundLight,
    surface = surfaceLight,
    onSurface = onSurfaceLight,
    surfaceVariant = surfaceVariantLight,
    onSurfaceVariant = onSurfaceVariantLight,
    outline = outlineLight,
    outlineVariant = outlineVariantLight,
    scrim = scrimLight,
    inverseSurface = inverseSurfaceLight,
    inverseOnSurface = inverseOnSurfaceLight,
    inversePrimary = inversePrimaryLight,
    surfaceDim = surfaceDimLight,
    surfaceBright = surfaceBrightLight,
    surfaceContainerLowest = surfaceContainerLowestLight,
    surfaceContainerLow = surfaceContainerLowLight,
    surfaceContainer = surfaceContainerLight,
    surfaceContainerHigh = surfaceContainerHighLight,
    surfaceContainerHighest = surfaceContainerHighestLight,
)


private val DarkColors = darkColorScheme(
    primary = primaryDark,
    onPrimary = onPrimaryDark,
    primaryContainer = primaryContainerDark,
    onPrimaryContainer = onPrimaryContainerDark,
    secondary = secondaryDark,
    onSecondary = onSecondaryDark,
    secondaryContainer = secondaryContainerDark,
    onSecondaryContainer = onSecondaryContainerDark,
    tertiary = tertiaryDark,
    onTertiary = onTertiaryDark,
    tertiaryContainer = tertiaryContainerDark,
    onTertiaryContainer = onTertiaryContainerDark,
    error = errorDark,
    onError = onErrorDark,
    errorContainer = errorContainerDark,
    onErrorContainer = onErrorContainerDark,
    background = backgroundDark,
    onBackground = onBackgroundDark,
    surface = surfaceDark,
    onSurface = onSurfaceDark,
    surfaceVariant = surfaceVariantDark,
    onSurfaceVariant = onSurfaceVariantDark,
    outline = outlineDark,
    outlineVariant = outlineVariantDark,
    scrim = scrimDark,
    inverseSurface = inverseSurfaceDark,
    inverseOnSurface = inverseOnSurfaceDark,
    inversePrimary = inversePrimaryDark,
    surfaceDim = surfaceDimDark,
    surfaceBright = surfaceBrightDark,
    surfaceContainerLowest = surfaceContainerLowestDark,
    surfaceContainerLow = surfaceContainerLowDark,
    surfaceContainer = surfaceContainerDark,
    surfaceContainerHigh = surfaceContainerHighDark,
    surfaceContainerHighest = surfaceContainerHighestDark,
)

@Composable
fun AppTheme(
    useDarkTheme: Boolean = isSystemInDarkTheme(),
    content: @Composable() () -> Unit
) {
    val colors = if (!useDarkTheme) {
        LightColors
    } else {
        DarkColors
    }

    val JosefinSans = FontFamily(
        Font(Res.font.KumbhSans_Regular),
//        Font(Res.font.Jost_Italic, style = FontStyle.Italic),
        Font(Res.font.KumbhSans_Medium, FontWeight.Medium),
//        Font(Res.font.Jost_MediumItalic, FontWeight.Medium, style = FontStyle.Italic),
        Font(Res.font.KumbhSans_Bold, FontWeight.Bold),
        Font(Res.font.KumbhSans_SemiBold, FontWeight.SemiBold),
        Font(Res.font.KumbhSans_ExtraBold, FontWeight.ExtraBold),
//        Font(Res.font.Jost_BoldItalic, FontWeight.Bold, style = FontStyle.Italic)
    )


    val defaultTypography = Typography()
    val Typography = Typography(
        displayLarge = defaultTypography.displayLarge.copy(fontFamily = JosefinSans),
        displayMedium = defaultTypography.displayMedium.copy(fontFamily = JosefinSans),
        displaySmall = defaultTypography.displaySmall.copy(fontFamily = JosefinSans),

        headlineLarge = defaultTypography.headlineLarge.copy(fontFamily = JosefinSans),
        headlineMedium = defaultTypography.headlineMedium.copy(fontFamily = JosefinSans),
        headlineSmall = defaultTypography.headlineSmall.copy(fontFamily = JosefinSans),

        titleLarge = defaultTypography.titleLarge.copy(fontFamily = JosefinSans),
        titleMedium = defaultTypography.titleMedium.copy(fontFamily = JosefinSans),
        titleSmall = defaultTypography.titleSmall.copy(fontFamily = JosefinSans),

        bodyLarge = defaultTypography.bodyLarge.copy(fontFamily = JosefinSans),
        bodyMedium = defaultTypography.bodyMedium.copy(fontFamily = JosefinSans),
        bodySmall = defaultTypography.bodySmall.copy(fontFamily = JosefinSans),

        labelLarge = defaultTypography.labelLarge.copy(fontFamily = JosefinSans),
        labelMedium = defaultTypography.labelMedium.copy(fontFamily = JosefinSans),
        labelSmall = defaultTypography.labelSmall.copy(fontFamily = JosefinSans)
    )

    MaterialTheme(
        colorScheme = colors,
        content = content,
        typography = Typography
    )
}