package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.Alignment
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.platform.UriHandler
import androidx.compose.ui.text.font.FontWeight
import kotlinx.coroutines.launch
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.plus
import org.hdg.sanaseppa.api.fetchGameData
import org.hdg.sanaseppa.getLocalSave
import org.jetbrains.compose.resources.painterResource
import sanaseppa.composeapp.generated.resources.Res
import sanaseppa.composeapp.generated.resources.lucide_up


fun dateToGameId(date: LocalDate): String {
    val year = date.year.toString()
    val month = date.monthNumber.toString().padStart(2, '0')
    val day = date.dayOfMonth.toString().padStart(2, '0')
    val gameId = year + month + day
    return gameId
}

fun getFinnishDayOfWeek(dayOfWeek: String): String {
    return when (dayOfWeek) {
        "MONDAY" -> "Maanantai"
        "TUESDAY" -> "Tiistai"
        "WEDNESDAY" -> "Keskiviikko"
        "THURSDAY" -> "Torstai"
        "FRIDAY" -> "Perjantai"
        "SATURDAY" -> "Lauantai"
        "SUNDAY" -> "Sunnuntai"
        else -> {
            ""
        }
    }
}

@Composable
fun AnswersScreen(stateShowAnswer: MutableState<Boolean>, gameId: String, uriHandler: UriHandler) {

    val year = gameId.substring(0, 4).toInt()
    val month = gameId.substring(4, 6).toInt()
    val day = gameId.substring(6, 8).toInt()
    val localDate = LocalDate(year, month, day)
    val previousDate = localDate.plus(-1, DateTimeUnit.DAY)

    val scope = rememberCoroutineScope()
    val selectedDate = remember { mutableStateOf(previousDate) }
    val stateGameWords = remember { mutableStateOf(emptyArray<String>()) }
    val stateWordOfTheDay = remember { mutableStateOf("") }
    val stateGameEnded = remember { mutableStateOf(true) }
    val guessedWords = remember { mutableStateOf(emptyList<String>()) }

    suspend fun getInitialData() {
        stateGameWords.value = emptyArray<String>()
        val gameData = fetchGameData(dateToGameId(selectedDate.value))
        if (gameData != null) {
            stateGameWords.value = gameData.game.allWords.toTypedArray()
            stateWordOfTheDay.value = gameData.game.word
        }
        val localSave = getLocalSave(dateToGameId(selectedDate.value))
        if (localSave.isNotEmpty()) {
            guessedWords.value = localSave.split(",")
        }
    }

    LaunchedEffect(key1 = "start") {
        getInitialData()
    }


    Column(
        Modifier.fillMaxHeight().widthIn(0.dp, 800.dp),
    ) {
        Text(fontSize = 24.sp, fontWeight = FontWeight.Medium, text = "Vastaukset")
        Spacer(modifier = Modifier.height(16.dp))
        Row(
            Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        )
        {
            Text(
                text = getFinnishDayOfWeek(selectedDate.value.dayOfWeek.name),
                fontSize = 16.sp,
                fontWeight = FontWeight.Bold,
            )
        }
        Row(
            Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        )
        {
            FilledTonalButton(
                modifier = Modifier.size(32.dp),
                contentPadding = PaddingValues(0.dp),
                shape = CircleShape,
                onClick = {
                    selectedDate.value = selectedDate.value.plus(-1, DateTimeUnit.DAY)
                    scope.launch {
                        getInitialData()
                    }
                }
            ) {
                Icon(
                    painter = painterResource(Res.drawable.lucide_up),
                    contentDescription = null,
                    modifier = Modifier.size(24.dp).rotate(-90f)
                )
            }
            Spacer(modifier = Modifier.width(16.dp))
            Text(
                text = "${
                    selectedDate.value.dayOfMonth.toString().padStart(2, '0')
                }.${selectedDate.value.monthNumber.toString().padStart(2, '0')}.${selectedDate.value.year}",
                fontSize = 16.sp,
                fontWeight = FontWeight.Medium,
            )
            Spacer(modifier = Modifier.width(16.dp))
            FilledTonalButton(
                modifier = Modifier.size(32.dp),
                contentPadding = PaddingValues(0.dp),
                shape = CircleShape,
                enabled = dateToGameId(selectedDate.value.plus(1, DateTimeUnit.DAY)) != gameId,
                onClick = {
                    selectedDate.value = selectedDate.value.plus(1, DateTimeUnit.DAY)
                    scope.launch {
                        getInitialData()
                    }
                }
            ) {
                Icon(
                    painter = painterResource(Res.drawable.lucide_up),
                    contentDescription = null,
                    modifier = Modifier.size(24.dp).rotate(90f)
                )
            }
        }
        if (stateGameWords.value.isNotEmpty()) {
            GridAnswers(
                guessedWords = guessedWords.value,
                allWords = stateGameWords.value,
                stateWordOfTheDay = stateWordOfTheDay,
                uriHandler = uriHandler,
                stateGameEnded = stateGameEnded,
            )
        } else {
            Spacer(modifier = Modifier.height(16.dp))
            Row(
                Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically
            )
            {
                Text("Ladataan...")
            }
        }
        Spacer(modifier = Modifier.weight(0.5f))
        Button(modifier = Modifier.fillMaxWidth(), onClick = { stateShowAnswer.value = false }) {
            Text("Sulje")
        }
    }
}