package org.hdg.sanaseppa.components

import androidx.compose.animation.core.*
import androidx.compose.foundation.layout.*
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.ui.tooling.preview.Preview
import ui.theme.*

@Composable
@Preview
fun UserInput(
    input: MutableState<String>,
    chosenLetter: String,
    isDarkmode: Boolean,
    stateChosenGameLetter: MutableState<String>,
    stateGameLetters: MutableState<Array<String>>
) {

    var allLetters = stateGameLetters.value + stateChosenGameLetter.value

    val chosenLetterChar = if (chosenLetter.length != 0) chosenLetter[0] else ' '
    val primaryColor = if (isDarkmode) primaryDark else primaryLight
    val infiniteTransition = rememberInfiniteTransition(label = "infinite")
    val alpha by infiniteTransition.animateFloat(
        initialValue = 1f,
        targetValue = 0f,
        animationSpec = infiniteRepeatable(
            animation = tween(1000, easing = LinearEasing),
            repeatMode = RepeatMode.Reverse
        )
    )

    Row(
        modifier = Modifier.fillMaxWidth()
            .height(IntrinsicSize.Min)
            .defaultMinSize(minHeight = 40.dp),
        horizontalArrangement = Arrangement.Center
    ) {
        input.value.forEach { char ->
            Text(
                color = if (char == chosenLetterChar) primaryColor else Color.Unspecified,
                fontSize = 32.sp,
                text = char.toString().uppercase(),
                modifier = Modifier.alpha(if (!allLetters.contains(char.toString())) 0.25f else 1f)
            )

        }
        VerticalDivider(thickness = 2.dp, color = primaryColor.copy(alpha = alpha))
    }
}