package org.hdg.sanaseppa

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.hdg.sanaseppa.api.fetchGameData
import org.jetbrains.compose.ui.tooling.preview.Preview
import ui.theme.*
import kotlinx.io.IOException
import org.hdg.sanaseppa.components.*
import androidx.compose.ui.platform.LocalFocusManager

@Composable
@Preview
fun App() {

    val focusManager = LocalFocusManager.current
    val pointsToBeatGame = 0.65

    val guessedWords = remember { mutableStateOf(emptyList<String>()) }

    val scope = rememberCoroutineScope()

    val stateGameId = remember { mutableStateOf("") }
    val stateWordOfTheDay = remember { mutableStateOf("") }
    val stateChosenGameLetter = remember { mutableStateOf("") }
    val stateGameLetters = remember { mutableStateOf(arrayOf("", "", "", "", "", "", "", "")) }
    val stateGameWords = remember { mutableStateOf(arrayOf("")) }
    val stateGameEnded = remember { mutableStateOf(false) }

    val stateUserInput = remember { mutableStateOf("") }

    val totalPoints = remember { mutableStateOf(guessedWords.value.sumOf { it.length }) }

    val lastPoints = remember { mutableStateOf(totalPoints.value) }

    val stateInputResponse = remember { mutableStateOf("") }
    val stateInputResponseShow = remember { mutableStateOf(false) }

    val stateAllGuessesVisible = remember { mutableStateOf(false) }

    val stateErrorVisible = remember { SnackbarHostState() }

    val uriHandler = LocalUriHandler.current

    val isDarkTheme = remember { mutableStateOf(false) }

    val stateShowAnswers = remember { mutableStateOf(false) }

    val stateShowInfo = remember { mutableStateOf(false) }

    val stateShowWinDialog = remember { mutableStateOf(false) }

    val stateShowRanksDialog = remember { mutableStateOf(false) }

    val stateShowLoading = remember { mutableStateOf(true) }

    suspend fun getInitialData() {
        setupKeyboardEventListener(
            stateUserInput, focusManager, onSendInput = {
                OnSendInput(
                    stateGameId,
                    stateGameLetters,
                    stateUserInput,
                    stateInputResponse,
                    stateInputResponseShow,
                    guessedWords,
                    stateChosenGameLetter,
                    stateGameWords,
                    stateWordOfTheDay,
                    totalPoints,
                    lastPoints
                )
            }
        )
        try {
            val localIsDarkTheme = getLocalSave("DarkTheme")
            if (localIsDarkTheme.isNotEmpty()) {
                isDarkTheme.value = localIsDarkTheme.toBoolean()
            }
            val gameData = fetchGameData("")
            if (gameData == null) {
                scope.launch {
                    val result = stateErrorVisible
                        .showSnackbar(
                            message = "Verkkovirhe",
                            actionLabel = "Yritä uudelleen",
                            duration = SnackbarDuration.Indefinite
                        )
                    when (result) {
                        SnackbarResult.ActionPerformed -> {
                            getInitialData()
                        }

                        SnackbarResult.Dismissed -> {

                        }
                    }
                }
                return
            }

            stateWordOfTheDay.value = gameData.game.word
            stateChosenGameLetter.value = gameData.game.chosenLetter
            stateGameLetters.value = gameData.game.letters.filter { it != gameData.game.chosenLetter }.toTypedArray()
            stateGameLetters.value.shuffle()
            stateGameWords.value = gameData.game.allWords.toTypedArray()
            stateGameId.value = gameData.game.id

            val localSave = getLocalSave(stateGameId.value)
            if (localSave.isNotEmpty()) {
                guessedWords.value = localSave.split(",")
            }
            totalPoints.value = guessedWords.value.sumOf { it.length }
            lastPoints.value = guessedWords.value.sumOf { it.length }

            val tipSeen = getLocalSave("RulesSeen")
            if (tipSeen.isEmpty() || tipSeen == "false") {
                setLocalSave("RulesSeen", "true")
                stateShowInfo.value = true
            }

            val gameEnded = getLocalSave("${stateGameId.value}GameEnded")
            if (gameEnded.isNotEmpty()) {
                stateGameEnded.value = true
            }
            stateShowLoading.value = false
        } catch (error: IOException) {
            scope.launch {
                val result = stateErrorVisible
                    .showSnackbar(
                        message = "Verkkovirhe",
                        actionLabel = "Yritä uudelleen",
                        duration = SnackbarDuration.Indefinite
                    )
                when (result) {
                    SnackbarResult.ActionPerformed -> {
                        getInitialData()
                    }

                    SnackbarResult.Dismissed -> {

                    }
                }
            }
        }
    }

    LaunchedEffect(key1 = "start") {
        getInitialData()
    }

    val totalCharacters = stateGameWords.value.sumOf { it.length }
    val stateProgress =
        (if (totalCharacters != 0) (totalPoints.value).toFloat() else 0.0).toFloat() / (totalCharacters.toFloat() * pointsToBeatGame)

    LaunchedEffect(key1 = stateInputResponseShow.value) {
        delay(1900)
        stateInputResponseShow.value = false
    }
    AppTheme(useDarkTheme = isDarkTheme.value) {
        Scaffold(snackbarHost = {
            SnackbarHost(hostState = stateErrorVisible)
        }) {
            Column(
                Modifier.fillMaxSize().padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                AnimatedVisibility(stateShowLoading.value) {
                    LoadingScreen()
                }
                AnimatedVisibility(stateShowWinDialog.value) {
                    DialogWin(stateShowWinDialog)
                }
                Row(
                    horizontalArrangement = Arrangement.End,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    ButtonHelp({
                        stateShowInfo.value = !stateShowInfo.value
                        stateShowAnswers.value = false
                    })
                    Spacer(modifier = Modifier.width(8.dp))
                    ButtonAnswers({
                        stateShowAnswers.value = !stateShowAnswers.value
                        stateShowInfo.value = false
                    })
                    Spacer(modifier = Modifier.width(8.dp))
                    DarkThemeSwitch({
                        isDarkTheme.value = !isDarkTheme.value
                        setLocalSave("DarkTheme", isDarkTheme.value.toString())
                    })
                }
                AnimatedVisibility(stateShowAnswers.value) {
                    AnswersScreen(stateShowAnswers, stateGameId.value, uriHandler)
                }
                AnimatedVisibility(stateShowInfo.value) {
                    InfoScreen(stateShowInfo)
                }
                GameProgress(
                    stateProgress,
                    (stateShowInfo.value || stateShowLoading.value || stateShowAnswers.value),
                    isDarkTheme.value,
                    totalPoints.value,
                    (totalCharacters * pointsToBeatGame).toInt(),
                    stateShowRanksDialog
                )
                ButtonGuesses(stateAllGuessesVisible, guessedWords.value, stateWordOfTheDay)
                AnimatedVisibility(stateAllGuessesVisible.value) {
                    ListGuesses(
                        stateGameId.value,
                        guessedWords.value,
                        stateGameWords.value,
                        stateProgress,
                        stateWordOfTheDay,
                        uriHandler,
                        stateGameEnded,
                        isDarkTheme.value
                    )
                }
                Spacer(modifier = Modifier.weight(0.45f))
                // Response
                Row(
                    modifier = Modifier.fillMaxWidth()
                        .height(IntrinsicSize.Min)
                        .defaultMinSize(minHeight = 50.dp),
                    horizontalArrangement = Arrangement.Center
                ) {
                    AnimatedVisibility(stateInputResponseShow.value) {
                        SuggestionChip(
                            onClick = {},
                            label = { Text(stateInputResponse.value) }
                        )
                    }
                }
                UserInput(
                    stateUserInput,
                    stateChosenGameLetter.value,
                    isDarkTheme.value,
                    stateChosenGameLetter,
                    stateGameLetters
                )
                Spacer(modifier = Modifier.weight(0.1f))
                InputButtons(stateUserInput, stateGameLetters, stateChosenGameLetter)
                Spacer(modifier = Modifier.weight(0.1f))
                BottomInputButtons(
                    stateGameId,
                    stateUserInput,
                    stateGameLetters,
                    stateInputResponse,
                    stateInputResponseShow,
                    guessedWords,
                    stateChosenGameLetter,
                    stateGameWords,
                    stateWordOfTheDay,
                    stateGameEnded.value,
                    stateShowWinDialog,
                    totalPoints,
                    lastPoints,
                    (totalCharacters * pointsToBeatGame).toInt(),
                    isDarkTheme.value
                )
                Spacer(modifier = Modifier.weight(0.3f))
            }
        }
    }
}