package org.hdg.sanaseppa.components

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Refresh
import androidx.compose.material3.Button
import androidx.compose.material3.FilledTonalButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.plus
import org.jetbrains.compose.resources.painterResource
import sanaseppa.composeapp.generated.resources.Res
import sanaseppa.composeapp.generated.resources.lucide_refresh
import sanaseppa.composeapp.generated.resources.lucide_up
import ui.theme.onPrimaryDark
import ui.theme.onPrimaryLight
import ui.theme.primaryDark
import ui.theme.primaryLight

@Composable
fun BottomInputButtons(
    stateGameId: MutableState<String>,
    stateUserInput: MutableState<String>,
    stateGameLetters: MutableState<Array<String>>,
    stateInputResponse: MutableState<String>,
    stateInputResponseShow: MutableState<Boolean>,
    guessedWords: MutableState<List<String>>,
    stateChosenGameLetter: MutableState<String>,
    stateGameWords: MutableState<Array<String>>,
    stateWordOfTheDay: MutableState<String>,
    gameEnded: Boolean,
    stateShowWinDialog: MutableState<Boolean>,
    totalPoints: MutableState<Int>,
    lastPoints: MutableState<Int>,
    pointsToBeatGame: Int,
    isDarkTheme: Boolean
) {

    LaunchedEffect(key1 = guessedWords.value) {
        if (lastPoints.value < pointsToBeatGame && totalPoints.value >= pointsToBeatGame) {
            delay(1500)
            stateShowWinDialog.value = true
        }
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(18.dp)
    ) {
        Button(onClick = {
            if (stateUserInput.value.isNotEmpty()) stateUserInput.value =
                stateUserInput.value.substring(0, stateUserInput.value.length - 1)
        }) {
            Text(modifier = Modifier.padding(4.dp), fontSize = 18.sp, text = "Poista")
        }
        FilledTonalButton(
            modifier = Modifier.size(54.dp),
            contentPadding = PaddingValues(0.dp),
            shape = CircleShape,
            onClick = {
                stateGameLetters.value = stateGameLetters.value.copyOf().apply { shuffle() }
            }
        ) {
            Icon(
                painter = painterResource(Res.drawable.lucide_refresh),
                contentDescription = null,
                modifier = Modifier.size(20.dp)
            )
        }
        Button(
            enabled = !gameEnded,
            onClick = {
                OnSendInput(
                    stateGameId,
                    stateGameLetters,
                    stateUserInput,
                    stateInputResponse,
                    stateInputResponseShow,
                    guessedWords,
                    stateChosenGameLetter,
                    stateGameWords,
                    stateWordOfTheDay,
                    totalPoints,
                    lastPoints
                )
            }) {
            Text(modifier = Modifier.padding(4.dp), fontSize = 18.sp, text = "Syötä")
        }
    }
}