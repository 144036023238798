package org.hdg.sanaseppa.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import org.jetbrains.compose.resources.painterResource
import sanaseppa.composeapp.generated.resources.Res
import sanaseppa.composeapp.generated.resources.win

@Composable
fun DialogWin(stateShowWinDialog: MutableState<Boolean>) {
    Dialog(onDismissRequest = { stateShowWinDialog.value = false }) {
        Card(Modifier.widthIn(0.dp, 800.dp)) {
            Image(
                painter = painterResource(Res.drawable.win),
                contentDescription = null
            )
            Text(
                fontSize = 24.sp,
                fontWeight = FontWeight.ExtraBold,
                modifier = Modifier.fillMaxWidth().padding(16.dp),

                text = "Hienoa työtä!",
                textAlign = TextAlign.Center
            )
            Text(
                modifier = Modifier.fillMaxWidth().padding(start = 16.dp),
                text = "Suoritit päivän tehtävän!"
            )
            Text(
                modifier = Modifier.fillMaxWidth().padding(start = 16.dp),
                text = "Voit jatkaa pelaamista ja keksiä sanoja lisää."
            )
            Button(onClick = { stateShowWinDialog.value = false }, modifier = Modifier.fillMaxWidth().padding(16.dp)) {
                Text("Sulje")
            }
        }
    }
}