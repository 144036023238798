package org.hdg.sanaseppa.components

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyHorizontalGrid
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.UriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.hdg.sanaseppa.setLocalSave
import ui.theme.primaryDark
import ui.theme.primaryLight

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ListGuesses(
    gameId: String,
    guessedWords: List<String>,
    allWords: Array<String>,
    stateProgress: Double,
    stateWordOfTheDay: MutableState<String>,
    uriHandler: UriHandler,
    stateGameEnded: MutableState<Boolean>,
    isDarkTheme: Boolean
) {

    val stateShowAlert = remember { mutableStateOf(false) }

    Column(
        Modifier.fillMaxHeight().widthIn(0.dp, 800.dp),
    ) {
        if (stateShowAlert.value) {
            AlertDialog(
                title = {
                    Text(text = "Haluatko varmasti näyttää vastaukset?")
                },
                text = {
                    Text(text = "Tämä lopettaa pelin etkä voi enää syöttää uusia sanoja")
                },
                onDismissRequest = { stateShowAlert.value = false },
                confirmButton = {
                    TextButton(
                        onClick = {
                            stateShowAlert.value = false
                            stateGameEnded.value = true
                            setLocalSave("${gameId}GameEnded", "true")
                        }
                    ) {
                        Text("Vahvista")
                    }
                },
                dismissButton = {
                    TextButton(
                        onClick = {
                            stateShowAlert.value = false
                        }
                    ) {
                        Text("Peruuta")
                    }
                }
            )
        }

        GridAnswers(
            guessedWords = guessedWords,
            allWords = allWords,
            stateWordOfTheDay = stateWordOfTheDay,
            uriHandler = uriHandler,
            stateGameEnded = stateGameEnded,
        )

        Row(Modifier.fillMaxWidth()) {
            Text(
                color = if (isDarkTheme) primaryDark else primaryLight,
                text = "Sanat: ${guessedWords.size}/${allWords.size}",
                fontWeight = FontWeight.Medium,
            )
            Spacer(modifier = Modifier.weight(0.85f))
            if (!stateGameEnded.value) {
                Text(
                    color = if (isDarkTheme) primaryDark else primaryLight,
                    text = "Näytä vastaukset",
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.clickable {
                        stateShowAlert.value = true
                    }
                )
            }
        }
        Spacer(modifier = Modifier.height(16.dp))
        Text("Klikkaamalla sanaa voit avata Kielitoimiston sanakirjan", fontWeight = FontWeight.Medium)
    }
}