@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package sanaseppa.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val lucide_refresh: DrawableResource by 
      lazy { init_lucide_refresh() }

  public val lucide_up: DrawableResource by 
      lazy { init_lucide_up() }

  public val win: DrawableResource by 
      lazy { init_win() }
}

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.lucide_refresh: DrawableResource
  get() = CommonMainDrawable0.lucide_refresh

private fun init_lucide_refresh(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:lucide_refresh",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/drawable/lucide_refresh.png", -1, -1),
    )
)

internal val Res.drawable.lucide_up: DrawableResource
  get() = CommonMainDrawable0.lucide_up

private fun init_lucide_up(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:lucide_up",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/drawable/lucide_up.png", -1, -1),
    )
)

internal val Res.drawable.win: DrawableResource
  get() = CommonMainDrawable0.win

private fun init_win(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:win",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/sanaseppa.composeapp.generated.resources/drawable/win.png", -1, -1),
    )
)
