package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Refresh
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.coroutines.delay
import org.hdg.sanaseppa.setLocalSave

@Composable
fun BottomInputButtons(
    stateGameId: MutableState<String>,
    stateUserInput: MutableState<String>,
    stateGameLetters: MutableState<Array<String>>,
    stateInputResponse: MutableState<String>,
    stateInputResponseShow: MutableState<Boolean>,
    guessedWords: MutableState<List<String>>,
    stateChosenGameLetter: MutableState<String>,
    stateGameWords: MutableState<Array<String>>,
    stateWordOfTheDay: MutableState<String>,
    gameEnded: Boolean,
    stateShowWinDialog: MutableState<Boolean>,
    totalPoints: Int,
    lastPoints: MutableState<Int>,
    pointsToBeatGame: Int,
) {

    LaunchedEffect(key1 = guessedWords.value) {
        if (lastPoints.value < pointsToBeatGame && totalPoints >= pointsToBeatGame) {
            delay(1500)
            stateShowWinDialog.value = true
        }
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(18.dp)
    ) {
        Button(onClick = {
            if (stateUserInput.value.isNotEmpty()) stateUserInput.value =
                stateUserInput.value.substring(0, stateUserInput.value.length - 1)
        }) {
            Text(modifier = Modifier.padding(4.dp), fontSize = 18.sp, text = "Poista")
        }
        RoundIconButton({
            stateGameLetters.value = stateGameLetters.value.copyOf().apply { shuffle() }
        }, size = 54.dp, imageVector = Icons.Filled.Refresh)
        Button(
            enabled = !gameEnded,
            onClick = {
                if (stateUserInput.value.isEmpty()) {

                } else if (stateUserInput.value.length <= 3) {
                    stateInputResponse.value = "Sana on liian lyhyt"
                    stateInputResponseShow.value = true
                    stateUserInput.value = ""
                }
                // All ready guessed
                else if (guessedWords.value.any { it == stateUserInput.value.lowercase() }) {
                    stateInputResponse.value = "Sana on jo arvattu"
                    stateInputResponseShow.value = true
                    stateUserInput.value = ""
                } else if (!stateUserInput.value.contains(stateChosenGameLetter.value)) {
                    stateInputResponse.value = "Arvauksessa ei ole keskikirjainta"
                    stateInputResponseShow.value = true
                    stateUserInput.value = ""
                }
                // New word found!
                else if (stateGameWords.value.contains(stateUserInput.value.lowercase())) {
                    stateInputResponse.value =
                        if (stateUserInput.value == stateWordOfTheDay.value) "+ ${stateUserInput.value.length}  Keksit päivän sanan!" else "+ ${stateUserInput.value.length}"
                    stateInputResponseShow.value = true
                    lastPoints.value = totalPoints
                    guessedWords.value += stateUserInput.value.lowercase()
                    stateUserInput.value = ""

                    setLocalSave(stateGameId.value, guessedWords.value.joinToString(separator = ","))
                }
                // Not on the list
                else {
                    stateInputResponse.value = "Sana ei ole listalla"
                    stateInputResponseShow.value = true
                    stateUserInput.value = ""
                }
            }) {
            Text(modifier = Modifier.padding(4.dp), fontSize = 18.sp, text = "Syötä")
        }
    }
}