package org.hdg.sanaseppa.components

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Info
import androidx.compose.runtime.Composable
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
@Preview
fun ButtonHelp(onClick: () -> Unit) {
    RoundIconButton({
        onClick()
    }, size = 32.dp, imageVector = Icons.Filled.Info)
}