package org.hdg.sanaseppa.api

import io.ktor.client.HttpClient
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.logging.LogLevel
import io.ktor.client.plugins.logging.Logging
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json

suspend fun fetchGameData(): GameResponse? {
    val client = HttpClient {
        install(Logging) {
            level = LogLevel.ALL
        }
        install(ContentNegotiation) {
            json(
                json = Json {
                    ignoreUnknownKeys = true
                }
            )
        }
    }
    try {
        val response: Response =
            client.get("https://api.sanaseppa.com/game").body()
        val json = Json { ignoreUnknownKeys = true }
        return json.decodeFromString(response.body)
    } catch (e: Exception) {
        println("Error: ${e.message}")
    }

    return null
}