package org.hdg.sanaseppa
actual fun setLocalSave(key: String, value: String): Unit = js(
    """{
localStorage.setItem(key, value)
}"""
)

actual fun getLocalSave(key: String): String = js(
    """{
        var save = localStorage.getItem(key)
        if(save === null)
        {
            return ""
        }
        
        return save
}
"""
)