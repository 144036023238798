package org.hdg.sanaseppa.components

import androidx.compose.runtime.MutableState
import org.hdg.sanaseppa.setLocalSave

fun OnSendInput(
    stateGameId: MutableState<String>,
    stateGameLetters: MutableState<Array<String>>,
    stateUserInput: MutableState<String>,
    stateInputResponse: MutableState<String>,
    stateInputResponseShow: MutableState<Boolean>,
    guessedWords: MutableState<List<String>>,
    stateChosenGameLetter: MutableState<String>,
    stateGameWords: MutableState<Array<String>>,
    stateWordOfTheDay: MutableState<String>,
    totalPoints: MutableState<Int>,
    lastPoints: MutableState<Int>,
) {

    val allLetters = stateGameLetters.value + stateChosenGameLetter.value

    if (stateUserInput.value.isEmpty()) {

    } else if (stateUserInput.value.length <= 3) {
        stateInputResponse.value = "Sana on liian lyhyt"
        stateInputResponseShow.value = true
        stateUserInput.value = ""
    }
    // if stateUserInput contains character that is not in allLetters
    else if (stateUserInput.value.any { !allLetters.contains(it.toString()) }) {
        stateInputResponse.value = "Arvauksessa on vääriä kirjaimia"
        stateInputResponseShow.value = true
        stateUserInput.value = ""
    }
    // All ready guessed
    else if (guessedWords.value.any { it == stateUserInput.value.lowercase() }) {
        stateInputResponse.value = "Sana on jo arvattu"
        stateInputResponseShow.value = true
        stateUserInput.value = ""
    } else if (!stateUserInput.value.contains(stateChosenGameLetter.value)) {
        stateInputResponse.value = "Arvauksessa ei ole keskikirjainta"
        stateInputResponseShow.value = true
        stateUserInput.value = ""
    }
    // New word found!
    else if (stateGameWords.value.contains(stateUserInput.value.lowercase())) {
        stateInputResponse.value =
            if (stateUserInput.value == stateWordOfTheDay.value) "+ ${stateUserInput.value.length}  Keksit päivän sanan!" else "+ ${stateUserInput.value.length}"
        stateInputResponseShow.value = true
        lastPoints.value = totalPoints.value
        guessedWords.value += stateUserInput.value.lowercase()
        totalPoints.value += stateUserInput.value.length
        stateUserInput.value = ""

        setLocalSave(stateGameId.value, guessedWords.value.joinToString(separator = ","))
    }
    // Not on the list
    else {
        stateInputResponse.value = "Sana ei ole listalla"
        stateInputResponseShow.value = true
        stateUserInput.value = ""
    }
}