package org.hdg.sanaseppa.components

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyHorizontalGrid
import androidx.compose.material3.Button
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.dp
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.UriHandler
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.withStyle
import kotlin.text.Typography.bullet

@Composable
fun GridAnswers(
    guessedWords: List<String>,
    allWords: Array<String>,
    stateWordOfTheDay: MutableState<String>,
    uriHandler: UriHandler,
    stateGameEnded: MutableState<Boolean>,
) {
    LazyHorizontalGrid(
        modifier = Modifier.fillMaxHeight(0.80f).fillMaxWidth(),
        rows = GridCells.Adaptive(minSize = 24.dp),
        verticalArrangement = Arrangement.spacedBy(16.dp),
        horizontalArrangement = Arrangement.spacedBy(64.dp),
        contentPadding = PaddingValues(horizontal = 16.dp, vertical = 16.dp)
    ) {
        if (!stateGameEnded.value) {
            val sortedGuessedWords = guessedWords.sortedBy { it }
            items(sortedGuessedWords.size) { item ->
                Text(
                    text = sortedGuessedWords[item],
                    fontWeight = if (sortedGuessedWords[item] == stateWordOfTheDay.value) FontWeight.ExtraBold else FontWeight.Medium,
                    modifier = Modifier.clickable {
                        uriHandler.openUri("https://www.kielitoimistonsanakirja.fi/#/${sortedGuessedWords[item]}")
                    })
            }
        } else {
            val sortedGuessedWords = allWords.sortedBy { it }
            items(sortedGuessedWords.size) { item ->
                Text(
                    color = if (guessedWords.contains(sortedGuessedWords[item])) Color.Unspecified else Color.Red,
                    text = sortedGuessedWords[item],
                    fontWeight = if (sortedGuessedWords[item] == stateWordOfTheDay.value) FontWeight.ExtraBold else FontWeight.Medium,
                    modifier = Modifier.clickable {
                        uriHandler.openUri("https://www.kielitoimistonsanakirja.fi/#/${sortedGuessedWords[item]}")
                    })
            }
        }
    }
}