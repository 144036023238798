package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.*
import androidx.compose.material3.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.dp
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.window.Dialog
import ui.theme.primaryDark
import ui.theme.primaryLight
import kotlin.math.ceil

@Composable
fun RanksDialog(
    stateShowRanksDialog: MutableState<Boolean>,
    guessedWordsCount: Number,
    rankNames: Array<String>,
    pointsNeededForNextRank: Int,
    num: Double,
    selectedIndex: Int,
    isDarkTheme: Boolean
) {
    Dialog(onDismissRequest = { stateShowRanksDialog.value = false }) {
        Card(Modifier.widthIn(0.dp, 800.dp)) {
            Column(Modifier.padding(24.dp)) {
                Text(
                    fontSize = 24.sp,
                    fontWeight = FontWeight.ExtraBold,
                    text = "Pisteet: $guessedWordsCount",
                )
                if (selectedIndex != RANK_NAMES.size - 1) {
                    val pointsText = if (pointsNeededForNextRank == 1) "piste" else "pistettä"
                    Text(
                        fontSize = 16.sp,
                        color = if (isDarkTheme) primaryDark else primaryLight,
                        text = "$pointsNeededForNextRank $pointsText seuraavaan tasoon",
                    )
                }
            }

            Row(
                modifier = Modifier.fillMaxWidth().padding(bottom = 8.dp, start = 24.dp, end = 24.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
            ) {
                Column {
                    Text(
                        fontWeight = FontWeight.Bold,
                        text = "Taso"
                    )
                }
                Column {
                    Text(
                        fontWeight = FontWeight.Bold,
                        text = "Minimipisteet"
                    )
                }
            }
            for (i in rankNames.indices.reversed()) {
                Row(
                    modifier = Modifier.fillMaxWidth().padding(horizontal = 24.dp),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    if (selectedIndex == i) {
                        Column {
                            Text(
                                fontSize = 18.sp,
                                fontWeight = FontWeight.SemiBold,
                                text = rankNames[i],
                                color = if (isDarkTheme) primaryDark else primaryLight
                            )
                        }
                        Column {
                            Text(
                                text = ceil(num * i).toString(),
                                color = if (isDarkTheme) primaryDark else primaryLight
                            )
                        }
                    } else {
                        Column {
                            Text(
                                text = rankNames[i],
                                modifier = Modifier.alpha(if (selectedIndex > i) 0.5f else 1f)
                            )
                        }
                        Column {
                            Text(
                                text = ceil(num * i).toString(),
                                modifier = Modifier.alpha(if (selectedIndex > i) 0.5f else 1f)
                            )
                        }
                    }
                }
            }
            Button(
                onClick = { stateShowRanksDialog.value = false },
                modifier = Modifier.fillMaxWidth().padding(16.dp)
            ) {
                Text("Sulje")
            }
        }
    }
}