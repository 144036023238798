package org.hdg.sanaseppa

import androidx.compose.runtime.MutableState
import androidx.compose.ui.focus.FocusManager
import kotlinx.browser.window
import org.w3c.dom.events.KeyboardEvent

actual fun setupKeyboardEventListener(
    stateUserInput: MutableState<String>,
    focusManager: FocusManager,
    onSendInput: () -> Unit
) {
    window.addEventListener("keydown", { event ->
        val keyboardEvent = event as KeyboardEvent
        if (keyboardEvent.key.length == 1) {
            // convert to lowercase
            stateUserInput.value += keyboardEvent.key.lowercase()
            focusManager.clearFocus()
        }
        // if keyboardEvent.key is backspace remove last char from stateUserInput.value
        if (keyboardEvent.key == "Backspace") {
            stateUserInput.value = stateUserInput.value.dropLast(1)
        }
        // if keyboardEvent.key is enter, call onSendInput
        if (keyboardEvent.key == "Enter") {
            onSendInput()
        }
    }).toString()

}

actual fun setLocalSave(key: String, value: String): Unit = js(
    """{
localStorage.setItem(key, value)
}"""
)

actual fun getLocalSave(key: String): String = js(
    """{
        var save = localStorage.getItem(key)
        if(save === null)
        {
            return ""
        }
        
        return save
}
"""
)